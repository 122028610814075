header{
  background:#15325f;
  padding: 15px 15px;
}

ul{
  padding:0 !important;
}

.queue{
  margin-bottom:20px;
}

span.queueName{
  font-size: 18px;
  border-bottom: solid 1px #ddd;
  width: 100%;
  display: block;
  font-weight: 500;
  text-align: center;
  background: #f5f5f5;
}

.agents{
  background:#f5f5f5;
  padding: 30px 25px;
  margin-top:30px !important;
}

.listname{
  color: #15325F;
  font-size: 1vw;
  font-weight: 500;
  margin-bottom: 20px;
  display: block;
}

ul li.agent{
  list-style-type: none;
  padding: 3px;
  line-height: 1.3vw;
  position: relative;
  overflow: hidden;
  min-height: 50px;
  text-shadow: 1px 1px 0 #fff;
}

ul li .icon{
  float: left;
  text-align: center;
  min-height: 75px;
  margin-right: 15px;
}

ul li.agent .icon i{
  font-size: 1.5vw;
}

ul li.agent .data{
  color: #7C868F;
  font-size: 1vw;
}

ul li.agent .agent-name{
  color: #00A1E0;
  font-size: 1vw;
}

.agent-timeinqueue{
  font-weight:bold;
}

.logo{
  width:5%;
}

.datetime-today{
  float: right;
  font-size: 2vw;
  color: #fff;
  font-weight: bold;
}

.wallboard-block{
  text-align: center;
}

.wallboard-block span{
  display: block;
  font-size: 5vw;
  font-weight: 300;
}

.wallboard-block span.label{
  font-weight: bold;
  font-size: 1.4vw;
}

.department{
    position: absolute;
    width: 98.5%;
    text-align: center;
    top: 15px;
    color: #ffffff;
    font-size: 2vw;
    font-weight: bold;
}

.choice-menu .row{
  min-height: 500px;
}

.choice-menu .choice-block{
  text-align: center;
  border: solid 1px #ddd;
  padding:30px;
  cursor: pointer;
}

.choice-menu .choice-block img{
  width: 20%;
  display: block;
  margin: auto;
  margin-bottom: 50px;
}

.choice-menu .choice-block h3{
  font-weight:bold;
  font-size: 2vw;
}

.queue .wallboard-block span{
  font-size: 3vw;
}

.queue .wallboard-block span.label{
  font-size: 1vw;
}